import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  nsUserSelector,
  refreshUser,
  loadNsUser,
} from "../appRedux/reducers/sentiment/user";

import asyncComponent from "util/asyncComponent";
import { Modal } from "antd";
import { Avatar, Checkbox, Tag, Tooltip } from "antd";
import { userApi } from "../api/user/user-api";
import { LoadingWidget } from "../components/LoadingWidget/LoadingWidget";
import { firebaseApp } from "../firebase";
import { useAuth } from "../authentication";
import { useAppDispatch } from "../NextApp";
import { ToastContainer } from "react-toastify";
import { Alarm } from "./Bist/Explore/Alarm";
import "react-toastify/dist/ReactToastify.css";
import { isProd } from "../util/Env";
import { setSymbols } from "../util/ImkbSemboller";

const App = ({ match }) => {
  const [agreed, setAgreed] = useState(false);
  const [waitingAgreement, setWaitingAgreement] = useState(false);
  const { authUser } = useAuth();

  const dispatch = useAppDispatch();
  const nsUser = useSelector(nsUserSelector);

  useEffect(() => {
    console.log(`Env: ${process.env.REACT_APP_ENV}`);
    setSymbols();
  }, []);

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboard"))}
        />
        <Route
          path={`${match.url}index`}
          component={asyncComponent(() => import("./Web"))}
        />
        <Route
          path={`${match.url}bist`}
          component={asyncComponent(() => import("./Bist"))}
        />
        <Route
          path={`${match.url}help`}
          component={asyncComponent(() => import("./Help"))}
        />
        <Route
          path={`${match.url}account`}
          component={asyncComponent(() => import("./Account"))}
        />
        <Route
          path={`${match.url}notifications`}
          component={asyncComponent(() => import("./Notifications"))}
        />
        <Route
          path={`${match.url}bulletins`}
          component={asyncComponent(() => import("./Bulletins"))}
        />
        <Route
          path={`${match.url}alarms`}
          component={asyncComponent(() => import("./Alarms"))}
        />
        <Route
          path={`${match.url}changelog`}
          component={asyncComponent(() => import("./ChangeLog"))}
        />
        <Route
          path={`${match.url}exchanger`}
          component={asyncComponent(() => import("./Exchanger"))}
        />
      </Switch>
      <ToastContainer></ToastContainer>
      {!isProd() && <Alarm />}
    </div>
  );
};

export default App;
